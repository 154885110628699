<template>
  <v-container fluid>
    <v-sheet class="d-flex mx-auto py-4" min-height="80vh" :width="$vuetify.breakpoint.mobile ? '92vw' : '80vw'">
      <v-row no-gutters>
        <!-- Section 1 -->
        <v-col cols="12" md="4" class="px-2 py-4 d-flex flex-column justify-space-between">
          <div>
            <v-row no-gutters justify="center">
              <v-col cols="8" class="text-center">
                <v-img class="mx-auto" :src="darkMode ? '/assets/KGuideLogo-Dark.png' : '/assets/KGuideLogo.png'" />
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-15">
              <div class="d-flex mx-auto mt-15 px-4">
                <p class="text-body-1 mb-0 text--secondary text-center font-weight-medium">Digital Lektionsplan til din køreskole <br />- nemt og gratis</p>
              </div>
            </v-row>
          </div>
          <div>
            <v-row>
              <v-col cols="4" offset="2">
                <v-img class="Hover" @click="appStoreLink" src="/assets/AppStore.png" />
              </v-col>
              <v-col cols="4" offset="">
                <v-img class="Hover" @click="googlePlayLink" src="/assets/GooglePlay.png" />
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-divider vertical inset />
        <!-- Section 2 -->
        <v-col cols="12" md="3" class="px-2 py-4 text-subtitle-1 d-flex flex-column justify-space-between">
          <div v-if="hasValidMitId">
            <v-row no-gutters class="text-left px-4">
              <p class="text-h6 text--secondary font-weight-medium mx-auto">Indtast stamoplysninger</p>
            </v-row>
            <div class="px-4">
              <v-form v-model="isValid" ref="newOrganizationFormRef">
                <v-row class="px-2 mt-4">Navn: * </v-row>
                <v-row class="px-2 my-1">
                  <v-text-field v-model="instructorName" disabled :rules="[rules.required]" />
                </v-row>
                <v-row class="px-2 mt-4">Køreskole navn: * </v-row>
                <v-row class="px-2 my-1">
                  <v-text-field v-model="organizationName" :rules="[rules.required]" />
                </v-row>
                <v-row class="px-2 mt-4">Email adresse: * </v-row>
                <v-row class="px-2 my-1">
                  <v-text-field v-model="email" :rules="[rules.required]" />
                </v-row>
                <v-row class="px-2 mt-4">Tlf. nummer: * </v-row>
                <v-row class="px-2 my-1">
                  <v-text-field v-model="phoneNumber" :rules="[rules.required]" />
                </v-row>
              </v-form>
            </div>
          </div>
          <div v-else>
            <v-row no-gutters class="text-left px-4">
              <p class="text-h6 text--secondary font-weight-medium mx-auto">Opret bruger</p>
            </v-row>
            <div class="d-flex">
              <!-- <MitIDsvg :svgColor="'#0060e6'" /> -->
              <v-btn @click="authorizeMitId" :loading="loadingMitId" class="mitIdButton mx-auto text-none">
                <v-img src="../assets/MitID-assets/MitID logo/MitID_logo.svg" width="85" class="mr-2" />
                Log ind med MitID
              </v-btn>
            </div>
          </div>
          <div>
            <v-row class="px-2" no-gutters dense>
              <r-btn block creation @click="createOrganization" :disabled="!isValid"> Opret </r-btn>
              <p class="text-caption">Felter med asterisk (*) er obligatoriske.</p>
            </v-row>
          </div>
        </v-col>
        <v-divider vertical inset />
        <!-- Section 3 -->
        <v-col cols="12" md="5" class="px-2 py-4 d-flex flex-column justify-space-between">
          <div class="text-left px-4">
            <p class="text-h6 text-center text--secondary my-auto">Hvad får jeg?</p>
            <p class="text-h6 mt-16 text--secondary">Køreskolen får et gratis værktøj til digitale lektionsplaner, styring af aftaler, elever og kørelærere.</p>
            <p class="text-h6 mt-8 text--secondary">Kørelæreren får en gratis app, hvor alle aftaler og lektionsplaner for eleverne er tilgængelige.</p>
            <p class="text-h6 mt-8 text--secondary">
              Eleverne kan downloade en betalingsapp, hvor de kan se deres fremskridt i lektionsplanen, se kørte ruter, booke aftaler og meget mere.
            </p>
          </div>
          <div class="text-left px-4">
            <p class="text-h6 text--secondary">Når du har trykket opret, modtager du en bekræftelsesmail og derefter er du i gang.</p>
            <p class="text-h6 text--secondary">Vi har lavet en standard lektionsplan til bil og MC som du kan tilrette så den passer til din køreskole</p>
          </div>
        </v-col>
      </v-row>
    </v-sheet>
    <v-dialog ref="dialogRef" width="650" v-model="showAlert" persistent>
      <v-card>
        <v-row no-gutters>
          <v-col cols="12" class="d-flex">
            <v-card-title>Køreskole oprettet</v-card-title>
          </v-col>
          <v-col cols="12">
            <v-card-subtitle class="body-1">
              Din køreskole er nu oprettet med en tilhørende brugerkonto.<br />
              Vi har sendt en e-mail til <strong>{{ email }}</strong> med et link til at aktivere din bruger.
            </v-card-subtitle>
          </v-col>
          <v-row no-gutters class="justify-center pa-2">
            <v-col cols="3" class="px-2">
              <r-btn color="grey" block @click="closeDialog"> Ok </r-btn>
            </v-col>
          </v-row>
        </v-row>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import apiService from '@/services/apiService';
import { mapGetters } from 'vuex';
import CriiptoAuth from '@criipto/auth-js';

export default {
  name: 'New_Organization_Page',
  data: () => ({
    isValid: false,
    showAlert: false,
    instructorName: '',
    organizationName: '',
    phoneNumber: '',
    email: '',

    mitIdToken: '',
    loadingMitId: false,
    hasValidMitId: false,
    rules: {
      required: (value) => !!value || 'Skal udfyldes',
    },
  }),
  computed: {
    ...mapGetters(['user', 'isStaff', 'isAdmin', 'darkMode']),
  },
  methods: {
    async authorizeMitId() {
      this.loadMitId();
      let criiptoAuth = new CriiptoAuth({
        domain: process.env.VUE_APP_CRIIPTO_DOMAIN,
        clientID: process.env.VUE_APP_CRIIPTO_CLIENT_ID,
        store: localStorage,
      });

      let response = await criiptoAuth.popup.authorize({
        width: 450,
        height: 750,
        redirectUri: process.env.VUE_APP_CRIIPTO_REDIRECT_URI,
        acrValues: process.env.VUE_APP_CRIITO_ACR,
      });

      this.organizationName = this.instructorName = (await apiService.getNameByMitId(response.id_token)).name;
      this.mitIdToken = response.id_token;

      this.hasValidMitId = true;
      this.loadingMitId = false;
    },

    async loadMitId() {
      this.loadingMitId = true;
      await new Promise((r) => setTimeout(r, 5000));
      this.loadingMitId = false;
    },

    appStoreLink() {
      console.log('Send link to AppStore');
      this.showSnackBar(`Download link not made yet`, `AppStore`, 'warning');
    },
    googlePlayLink() {
      console.log('Send link to GooglePlay');
      this.showSnackBar(`Download link not made yet`, `GooglePlay`, 'warning');
    },

    clearData() {
      this.instructorName = '';
      this.organizationName = '';
      this.email = '';
      this.phoneNumber = '';
      this.hasValidMitId = false;
    },

    showSnackBar(title, body, color) {
      this.$store.commit('alert', {
        show: true,
        title: title,
        color: color,
        textColor: 'white',
        message: body,
      });
    },

    async createOrganization() {
      try {
        await apiService
          .createNewOrgWithUser({
            OrgName: this.organizationName,
            OrgEmail: this.email,
            OrgPhone: this.phoneNumber,
            MitIdToken: this.mitIdToken,
            Email: this.email,
          })
          .then(() => {
            this.showSnackBar(`Køreskole oprettet`, `Navn: ${this.organizationName}<br/>Brugernavn: ${this.email}`, 'success');
            this.showAlert = true;
          });
      } catch (error) {
        console.log('Error > ', error);
      }
    },
    async closeDialog() {
      this.showAlert = false;
      this.clearData();
      this.$store.dispatch('LOGOUT');
      this.$router.push({ name: 'FrontPage' });
    },
  },
  created() {
    this.clearData();
  },
};
</script>

<style lang="scss">
.Hover {
  cursor: pointer;
}

.mitIdButton {
  background: #0060e6 !important;
  color: #ffffff !important;
  font-family: 'IBM Plex Sans Semi-Bold', Helvetica, Arial, sans-serif !important;
  border-radius: 4px !important;
  height: 48px !important;
  padding: 0.25rem 0.75rem 0.25rem 1rem !important;
}
</style>